import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            textAlign: 'center',
            color: '#66FCF1',
            marginTop: 40,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: "underline"
            }
        }
    })
);

interface SiteLinkProps {
    title: string;
    onClick(): void;
}

const SiteLink: React.FC<SiteLinkProps> = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.link} onClick={props.onClick}>
            {props.title}
        </div>
    );
};

export default SiteLink;