import React, { useEffect } from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

// containers


const logo = process.env.PUBLIC_URL + '/np-logo-clear.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            zIndex: 999,
            position: 'relative',
            cursor: 'pointer',
            padding: 40
        },
        icon: {
            textAlign: 'center'
        },
        text: {
            textAlign: 'center',
            color: '#c5c6c7'
        }
    })
);

interface LinkProps {
    title: string;
    url: string;
    icon: any;
}

const Link: React.FC<LinkProps> = (props) => {

    const classes = useStyles();
    // const [loading, setLoading] = useState<boolean>(false);

    const handleOnClick = () => {
        window.open(props.url, '_blank');
    }

    useEffect(() => {
        // load
    });

    return (
        <div className={classes.container} onClick={handleOnClick}>
            <div className={classes.icon}>{props.icon}</div>
            <div className={classes.text}>{props.title}</div>
        </div>
    );
};


export default Link;
