import React from 'react';
import Particles from "react-tsparticles";

import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import NoFound from './pages/home/NotFound';
import Home from './pages/home/Home';
import Portfolio from './pages/portfolio/Portfolio';

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="*" element={<NoFound />} />
      </Routes>
      {/* <Particles 
        id="particles"
        canvasClassName="background"
        options={{
          background: {
            color: {
              value: "#0d47a1",
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            }
          }
        }} 
      /> */}
    </Router>
  );
}

export default App;
