import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

// components
import SiteLink from '../../components/site/SiteLink';

// containers
import AppContainer from '../../containers/App';
import Link from './components/Link';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const logo = process.env.PUBLIC_URL + '/np-logo-clear.png';
const profile = process.env.PUBLIC_URL + '/profile.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            zIndex: 999,
            position: 'relative',
            height: '100%'
        },
        icon: {
            fontSize: 72,
            color: '#66FCF1',
            border: "1px solid #1f2833",
            padding: 10,
            '&:hover': {
                border: "1px solid #45a29e",
                borderRadius: 5
            }
        },
        profile: {
            display: 'inline-block',
            width: 200,
            height: 200,
            background: `url(${profile})`,
            backgroundPosition: '50% 50%',
            backgroundSize: 'cover',
            borderRadius: '50%',
            border: "2px solid #c5c6c7"
        },
        profileContainer: {
            display: 'flex',
            justifyContent: 'center',
            
        },
        links: {
            display: 'flex',
            justifyContent: 'center',
        },
        portfolio: {
            textAlign: 'center',
            color: '#66FCF1',
            marginTop: 40,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: "underline"
            }
        }
    })
);

interface HomepageProps { }

const Homepage: React.FC<HomepageProps> = (props) => {

    const classes = useStyles();
    let navigate = useNavigate();

    const handleOnClick = () => {
        navigate("/portfolio", { replace: true });
    }

    useEffect(() => {
        // load
    });

    return (
        <AppContainer maxWidth="lg">
            <div className={classes.container}>
                <div>
                    <div className={classes.profileContainer}>
                        <div className={classes.profile} />
                    </div>
                    <div className={classes.links}>
                        <Grid container>
                            <Grid md={3} xs={6}>
                                <Link title="Linkedin" url="https://www.linkedin.com/in/jonathongray/" icon={<LinkedInIcon className={classes.icon} />} />
                            </Grid>
                            <Grid md={3} xs={6}>
                                <Link title="Github" url="https://github.com/jdgray" icon={<GitHubIcon className={classes.icon} />} />
                            </Grid>
                            <Grid md={3} xs={6}>
                                <Link title="Twitter" url="https://twitter.com/jdgray" icon={<TwitterIcon className={classes.icon} />} />
                            </Grid>
                            <Grid md={3} xs={6}>
                                <Link title="Facebook" url="https://www.facebook.com/jonathon.gray" icon={<FacebookIcon className={classes.icon} />} />
                            </Grid>
                        </Grid>
                    </div>
                    {/* <SiteLink title="View Portfolio" onClick={handleOnClick} /> */}
                </div>
            </div>
        </AppContainer>
    );
};


export default Homepage;
