import React, { Component } from 'react';
// material
import Container from '@material-ui/core/Container';
import Header from '../components/site/Header';
import Footer from '../components/site/Footer';

const styles: any = {
  container: {
    width: '100%',
    minHeight: '100%',
  },
  bodyContainer: {
    minHeight: 600,
    marginBottom: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

interface AppContainerProps {
  children: any;
  showHeader?: boolean;
  loading?: boolean;
  showOption?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

class AppContainer extends Component<AppContainerProps, {}> {

  componentDidMount = () => {

  }

  render() {
    const maxWidth = this.props.maxWidth || false;
    return (
      <div style={styles.container}>
        { this.props.showHeader && <Header /> }
        <Container maxWidth={maxWidth}>
          <div style={styles.bodyContainer}>
            {this.props.children}
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default AppContainer;