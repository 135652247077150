import React from 'react'
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import AppContainer from '../../containers/App';
import SiteLink from '../../components/site/SiteLink';

const logo = process.env.PUBLIC_URL + '/images/not-today.gif';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    },
    notFound: {
      fontSize: 48,
      textAlign: 'center',
      padding: 40,
      color: '#c5c6c7'
    },
    imageContainer: {
      paddingTop: 40,
      display: 'flex',
      justifyContent: 'center',
    },
    image: {
      width: 600,
      borderRadius: 20
    },
    home: {
      textAlign: 'center',
      cursor: 'pointer'
    }
  })
);


const NotFound: React.FC<{}> = () => {

  const classes = useStyles();
  let navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/", { replace: true });
  }

  return (
    <AppContainer maxWidth="md">
      <div className={classes.container}>
        <div>
          <div className={classes.notFound}>Page Not Found</div>
          <div className={classes.imageContainer}>
            <img src={logo} className={classes.image} alt="nowperformance logo" />
          </div>
          <SiteLink title="There’s no place like home" onClick={handleOnClick} />
        </div>
      </div>
    </AppContainer>
  )
}
export default NotFound;