import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const profile = process.env.PUBLIC_URL + '/profile.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 20
        },
        profileContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        profile: {
            display: 'inline-block',
            width: 50,
            height: 50,
            background: `url(${profile})`,
            backgroundPosition: '50% 50%',
            backgroundSize: 'cover',
            borderRadius: '50%',
            border: "2px solid #c5c6c7"
        },
        home: {
            display: 'flex',
            color: '#66FCF1',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: "underline"
            }
        },
        link: {
            paddingTop: 2,
            paddingLeft: 5
        }
    })
);

const Header: React.FC<{}> = (props) => {

    const classes = useStyles();
    let navigate = useNavigate();

    const handleOnClick = () => {
        navigate("/", { replace: true });
    }

    return (
        <div className={classes.container}>
            <div>
                <div className={classes.home} onClick={handleOnClick}>
                    <ArrowBackIcon />
                    <div className={classes.link}>Home</div>
                </div>
            </div>
            <div className={classes.profileContainer}>
                <div className={classes.profile} />
            </div>
        </div>
    );
};

export default Header;
