import React, { useEffect } from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

// containers
import AppContainer from '../../containers/App';

const projects = [
    {
        title: '',
        description: '',
        image: ''
    }
]

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin: 0,
            height: ''
        }
    })
);

interface ProjectsProps {}

const Portfolio: React.FC<ProjectsProps> = (props) => {

    const classes = useStyles();
    // const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // load
    });

    return (
        <AppContainer maxWidth="lg" showHeader>
            <div className={classes.container}>
                Portfolio
            </div>
        </AppContainer>
    );
};


export default Portfolio;
