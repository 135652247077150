import React, { useEffect } from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: 2,
            marginLeft : 2,
            color: '#c5c6c7',
            position: 'fixed',
            bottom: 0,
            fontSize: 10
        },
        text: {

        }
    })
);

const Footer: React.FC<{}> = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.text}>Website built with: React, Tyepscript, Webpack. Hosted on AWS</div>
        </div>
    );
};

export default Footer;
